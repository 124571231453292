import {
  addDays,
  differenceInDays,
  eachDayOfInterval,
  eachMonthOfInterval,
  format,
  formatRelative,
  isThisYear,
  parse,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns'
import { enGB } from 'date-fns/locale'

type Option = { label: string; value: string }

// from: https://github.com/date-fns/date-fns/issues/644
export const getDayList = (): Array<Option> => {
  const firstDayOfWeek = startOfWeek(new Date())

  const dayArray = Array.from(Array(7)).map((e, i) => {
    const dayString = format(addDays(firstDayOfWeek, i), 'EEEE')
    return {
      label: dayString,
      value: dayString,
    }
  })

  return dayArray
}

export const dayFromDate = (value: string): string => {
  let content = value
  const reg = new RegExp(/(\d{4})-(\d{2})-(\d{2})/)
  if (reg.test(value)) {
    const parsedDate = parse(value, 'yyyy-MM-dd', new Date())
    content = format(parsedDate, 'd')
  }

  return content
}

export const dateRange = (step: 'day' | 'month', amount: number): Date[] => {
  if (step === 'day') {
    return eachDayOfInterval({
      start: subDays(new Date(), amount - 1),
      end: new Date(),
    })
  }

  return eachMonthOfInterval({
    start: subMonths(new Date(), amount - 1),
    end: new Date(),
  })
}

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
}

const relativeLocaleWithoutTime = {
  ...enGB,
  formatRelative: (token: string) =>
    formatRelativeLocale[token as keyof typeof formatRelativeLocale],
}
export const formatDate = (date: Date, { relative } = { relative: false }) => {
  const formatString = isThisYear(date) ? 'd MMM' : 'd MMM yy'
  const absolute = format(date, formatString)

  if (!relative) return absolute

  const diff = differenceInDays(new Date(), date)
  if (diff > 5 || diff < -5) return absolute
  return formatRelative(date, new Date(), { locale: relativeLocaleWithoutTime })
}

export const formatDateWithTime = (date: Date): string => {
  return format(date, 'HH:mm, dd MMM yy')
}
